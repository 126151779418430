import styled from "styled-components"
import Heading from "../../Heading"

export const BoxLargeImgInner = styled.div`
  transition: ${props => props.theme.transition};
`

export const BoxLargeImgMedia = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  img {
    width: 100%;
    transition: transform 1s;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.8;
    background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%);
  }
`

export const BoxLargeImgHeading = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 250px;
  margin: 0 auto;
  z-index: 2;
  overflow: hidden;
  transition: ${props => props.theme.transition};
`

export const BoxLargeImgContent = styled.div`
  margin-top: 30px;
`

export const HeadingWrap = styled(Heading)`
  font-size: ${props => props.fontSize || "24px"};
  line-height: ${props => props.lineHeight || 1.5};
  color: ${props => props.color || "#fff"};
  font-weight: ${props => props.fontweight || 500};
`

export const TextWrap = styled.p`
  color: ${props => props.color || "#7e7e7e"};
  text-align: ${props => props.align || "center"};
`

export const BoxLargeImgWrap = styled.div`
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ${BoxLargeImgInner} {
    height: 100%;
  }
  &:hover {
    ${BoxLargeImgInner} {
      transform: translateY(-5px);
    }
  }
`
