import React from "react"
import SEO from "../components/seo"
import ServiciosArea from "../containers/servicios"

const SeccionServicios = () => (
  <>
    <SEO title="Servicios Seggasa" />
    <main className="site-wrapper-reveal">
      <ServiciosArea />
    </main>
  </>
)

export default SeccionServicios
