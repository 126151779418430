import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Img from "gatsby-image"
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgHeading,
  HeadingWrap,
  TextWrap,
  BoxLargeImgContent,
} from "./box-large-image.style"

const BoxLargeImage = ({ imageSrc, title, desc, ...boxStyles }) => {
  const { headingStyle, descStyle, buttonStyle, ...restStyles } = boxStyles
  let boxImage
  if (imageSrc.fixed && typeof imageSrc.fixed !== "function") {
    boxImage = <Img fixed={imageSrc.fixed} alt={title} />
  } else if (imageSrc.fluid) {
    boxImage = <Img fluid={imageSrc.fluid} alt={title} />
  } else {
    boxImage = <img src={imageSrc} alt={title} />
  }

  return (
    <BoxLargeImgWrap {...restStyles}>
      <BoxLargeImgInner>
        {imageSrc && (
          <BoxLargeImgMedia>
            {boxImage}
            <BoxLargeImgHeading>
              <HeadingWrap as="h5" content={title} {...headingStyle}>
                {title}
              </HeadingWrap>
            </BoxLargeImgHeading>
          </BoxLargeImgMedia>
        )}
        <BoxLargeImgContent>
          {desc && <TextWrap {...descStyle}>{parse(desc)}</TextWrap>}
        </BoxLargeImgContent>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  )
}

BoxLargeImage.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desc: PropTypes.string,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
}

export default BoxLargeImage
