import React from "react"
import PropTypes from "prop-types"
import { ServiciosWrapper } from "./style"
import { IoIosArrowRoundForward } from "react-icons/io"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../components/UI/wrapper"
import SectionTitle from "../../components/UI/section-title"
import BoxLargeImage from "../../components/box-large-image/layout-one"
import Anchor from "../../components/UI/anchor"
import Text from "../../components/Text"

const Servicios = ({ sectionTitleStyle, linkStyle, sectionBottomStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      allPrismicServicio {
        edges {
          node {
            data {
              descripcion {
                text
              }
              titulo {
                text
              }
              foto {
                url
              }
            }
          }
        }
      }
    }
  `)

  const boxContents = Data.allPrismicServicio.edges
  return (
    <ServiciosWrapper>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle {...sectionTitleStyle} title="Servicio Técnico" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text
              content="
        La característica principal de la personalidad empresarial de SEGGASA lo constituye el soporte técnico que brindamos recomendando a nuestros clientes el producto adecuado para cada necesidad o aplicación a través de nuestros COORDINADORES COMERCIALES.
        "
            />
            <Text
              content="
        En la actualidad SEGGASA. ha puesto en operación VEHÍCULOS DE ASESORÍA TÉCNICA, que se desempeñan como UNIDADES DE NEGOCIOS independiente, cuya finalidad es la de ofrecer una diversidad de servicios  para el segmento industrial (montacargas) en forma regular, para lo cual cuenta con equipamiento de primera tecnología y una asesoría constante.
        "
            />
          </Col>
        </Row>
        <Row {...sectionBottomStyle}>
          <Col lg={12}>
            <Anchor
              {...linkStyle}
              path="https://intensitycalculator.camso.co/es/res"
            >
              Consulta el modelo correcto que necesitas aquí
              <IoIosArrowRoundForward className="icon" />
            </Anchor>
          </Col>
        </Row>
        <Row>
          <Col>
            <SectionTitle title="Los servicios que ofrecemos son:" />
          </Col>
        </Row>
        <Row className="gutter-xl-70">
          {boxContents.map((boxContent, index) => {
            return (
              <Col
                lg={6}
                md={6}
                className="box-item"
                key={`box-image-${index}`}
              >
                <BoxLargeImage
                  title={boxContent.node.data.titulo[0].text}
                  desc={boxContent.node.data.descripcion[0].text}
                  imageSrc={boxContent.node.data.foto.url}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
      >
    </ServiciosWrapper>
  )
}

Servicios.propTypes = {
  sectionTitleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  sectionBottomStyle: PropTypes.object,
}

Servicios.defaultProps = {
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      medium: {
        mb: "17px",
      },
    },
  },
  linkStyle: {
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
  sectionBottomStyle: {
    mt: "40px",
    align: "center",
  },
}

export default Servicios
